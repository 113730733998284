<template>
    <div class="addMealDemand">
        <Header backType="white" :back="true" />
        <div class="publicHead">
            <p>外出用餐</p>
        </div>
        <div>
            <div class="btnRow">
                <div class="btnRowTitle">用餐类型</div>
                <div class="btnRowGroup">
                    <Btn
                        v-for="(item, index) in itemNameList"
                        :key="index"
                        :btnClass="input.itemName == item.txt ? 'btnRdio':'btnRdio btnRdioDis'"
                        @callBack="handleRadio(item, 'itemName')"
                    >
                        <span slot="btnContent">{{item.txt}}</span>
                    </Btn>
                </div>
            </div>
            <div class="btnRow">
                <div class="btnRowTitle">用餐形式</div>
                <div class="btnRowGroup">
                    <Btn
                        v-for="(item, index) in mealTypeList"
                        :key="index"
                        :btnClass="input.mealType == item.txt ? 'btnRdio':'btnRdio btnRdioDis'"
                        @callBack="handleRadio(item, 'mealType')"
                    >
                        <span slot="btnContent">{{item.txt}}</span>
                    </Btn>
                </div>
            </div>
            <div class="normalInputRow">
                <MInput title="用餐人数" pla="请输入用餐人数" v-model="input.qty1"></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="预计金额" pla="请输入预计金额" v-model="input.amount"></MInput>
            </div>
            <div class="normalInputRow" @click="openComponent('eventDate', 'dtStart')">
                <MInput title="开始时间" :disabled="true" pla="请选择开始时间" v-model="input.dtStart"></MInput>
            </div>
            <div class="normalInputRow" @click="openComponent('eventDate', 'dtEnd')">
                <MInput title="结束时间" :disabled="true" pla="请选择结束时间" v-model="input.dtEnd"></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="订餐人姓名" pla="请输入订餐人姓名" v-model="input.bookingName"></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="订餐人手机" pla="请输入订餐人手机号" v-model="input.bookingPhoneNum"></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="备注" pla="请输入备注信息" v-model="input.descr"></MInput>
            </div>
        </div>
        <div>
            <div v-if="shopType == '平台到餐'">
                <div class="choose-shop-title" @click="handleClick">选择餐厅</div>
                <div class="shop-item-btn" v-if="input.shopName">
                    <img src="../../assets/img/logo.png" />
                    <div class="shop-name">{{input.shopName}}</div>
                    <Btn btnClass="delate-shop" @callBack="delateShop">
                        <span slot="btnContent">
                            <Svgs color="#fff" name="iconshanchu2"></Svgs>
                        </span>
                    </Btn>
                </div>
                <div v-else class="add-btn" @click="openMeal">
                    <Svgs color="#C0C4CD" name="iconadd"></Svgs>
                </div>
            </div>
            <Btn btnClass="submit-shop" @callBack="submit">
                <span slot="btnContent">保存</span>
            </Btn>
        </div>
        <DatePicker title="选择会议日期" ref="eventDate" :isRadio="true" @setDate="setDate"></DatePicker>
        <TimePicker title="选择会议时间" ref="time" @setTime="chooseSelect" :single="true"></TimePicker>
    </div>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { mapGetters } from "vuex";
import { baseUrl } from "@/service/baseUrl.js";
import {
    mealAddDemand,
    mealAddDemandXianXia
} from "@/config/mealAddDemand.js";
import {
    SmartStorage
} from 'smart-core-util'
export default {
    data() {
        return {
            show: false,
            input: {
                itemName: "", // 用餐类型
                mealType: "", // 用餐形式
                qty1: "", // 用餐人数
                amount: "", // 预计金额
                dtStart: "", // 用餐开始时间
                dtEnd: "", // 用餐结束时间
                bookingName: "", // 订餐人姓名
                bookingPhoneNum: "", // 订餐人电话
                descr: "", // 备注
                isMeetingPlace: true, //会议场所
                isExtra: "true", //"true"为线上餐，"false"为线下餐
                shopName: "", //餐厅名字
                shopMobile: "", //餐厅电话
                shopAddress: "", //餐厅地址
                province: "", //餐厅省份
                city: "", //餐厅城市
                cityRegion: "" //餐厅城市区域
            },
            shopType: "",
            itemNameList: [{ txt: "午餐" }, { txt: "晚餐" }],
            mealTypeList: [
                { txt: "自助餐" },
                { txt: "围餐" },
                { txt: "商务用餐" }
            ],
            delayClick: null, //连续点击300毫秒延迟
            dateType: ''
        };
    },
    computed: {
        ...mapGetters(["event", "orderDetail"])
    },
    created() {
        this.init();
        this.delayClick = new this.delay();
    },
    methods: {
        handleClick: function() {
            this.show = true;
        },
        handleRadio(item, type) {
            if (type == "mealType") {
                this.input.mealType = item.txt;
            } else if (type == "itemName") {
                this.input.itemName = item.txt;
            }
        },
        openComponent(type, dateType) {
            this.dateType = dateType
            this.$refs[type].openPop();
        },
        setDate(data) {
            if(this.dateType == 'dtStart') {
                this.input.dtStart = data.dayTxt
            } else {
                this.input.dtEnd = data.dayTxt
            }
            this.$refs.time.openPop()
        },
        chooseSelect(data) {
            if(this.dateType == 'dtStart') {
                this.input.dtStart = this.input.dtStart + " " + data.start
            } else {
                this.input.dtEnd = this.input.dtEnd + " " + data.start
            }
        },
        init() {
            this.shopType = this.$route.query.shopType || "平台到餐";
            let orderDetail = this.orderDetail;
            let itsItemData = orderDetail.itsItemData || {};
            if (orderDetail.itemId) {
                this.input = {
                    itemId: orderDetail.itemId,
                    itemName: orderDetail.itemName,
                    mealType: itsItemData.mealType,
                    isExtra: orderDetail.isExtra,
                    qty1: orderDetail.qty1,
                    amount: orderDetail.amount,
                    dtStart: this.fomatDateTime(orderDetail.dtStart),
                    dtEnd: this.fomatDateTime(orderDetail.dtEnd),
                    isMeetingPlace: true,
                    shopName: itsItemData.shopName,
                    bookingName: itsItemData.bookingName,
                    bookingPhoneNum: itsItemData.bookingPhoneNum,
                    descr: orderDetail.descr
                };
                if (orderDetail.isExtra) {
                    this.input.shopRowId = itsItemData.shopId;
                    this.input.sourceCode = orderDetail.sourceCode;
                    let temp = (orderDetail.itsItemData || { payProfile: "" })
                        .payProfile || { PayModes: [] };
                    let payMode =
                        typeof temp.PayModes == "object"
                            ? temp.PayModes[0]
                            : temp.PayModes;
                    if (payMode == 7) {
                        this.shopType = "美团到餐";
                    } else if (payMode == 9) {
                        this.shopType = "商宴通";
                    }
                } else {
                    this.input.shopMobile = itsItemData.mobile;
                    this.input.shopAddress = itsItemData.address;
                    this.input.province = itsItemData.province;
                    this.input.city = itsItemData.city;
                    this.input.cityRegion = itsItemData.cityRegion;
                }
            }
        },
        fomatDateTime(value) {
            if (!value) return;
            if (value.replace) {
                value = value.replace(/T/g, " ");
                value = value.replace(/-/g, "/");
            }
            var date = new Date(value);
            var year = date.getFullYear();
            var month =
                date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1;
            var _date =
                date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            var hour =
                date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            var minute =
                date.getMinutes() < 10
                    ? "0" + date.getMinutes()
                    : date.getMinutes();
            var dateTime =
                year + "-" + month + "-" + _date + " " + hour + ":" + minute;
            return dateTime;
        },
        // 选择餐厅
        openMeal() {
            // this.input.shopName = "妙手湘湖南湘菜馆(澳门路店)";
            // this.input.shopRowId = 987639;
            // this.input.sourceCode = "KouBei";

            // this.input.shopName = "小南国 世茂店";
            // this.input.shopRowId = 1135176;
            // this.input.sourceCode = 'XiaoMiShu'
            // return

            // this.input.shopName = "肯德基(新世界百货店)"
            // this.input.isExtra = false
            // this.isExtraName = this.isExtras[String(false)];
            // this.input.shopAddress = "四川省成都市锦江区盐市口街道中环广场新世界百货(成都店)";
            // this.input.province =  "四川省";
            // this.input.city =  "成都市";
            // this.input.cityRegion = "锦江区";
            // return;
            let _this = this;
            const tenantcode = this.event.tenant_code || "roche";
            let eventData = {
                ...this.event,
                city: this.event.cityDictVal //用餐城市
            };
            SmartStorage.set("eventData", JSON.stringify(eventData), {
                domain: window.idomain
            });
            const options = {
                loadUrl: `${baseUrl.iShopUrl}/index?tenantcode=${tenantcode}`,
                callback: viewData => {
                    viewData = JSON.parse(viewData);
                    _this.input.shopName = viewData.fullName;
                    _this.input.isExtra = viewData.isExtra;
                    if (viewData.isExtra) {
                        _this.input.shopRowId = viewData.rowId;
                        _this.input.sourceCode = viewData.sourceCode;
                    } else {
                        _this.input.shopAddress = viewData.shopAddress;
                        _this.input.province = viewData.province;
                        _this.input.city = viewData.city;
                        _this.input.cityRegion = viewData.cityRegion;
                    }
                }
            };
            this.iJsBridge.openWebapp(options);
        },
        delateShop() {
            this.input.shopName = "";
            this.input.isExtra = "true";
            delete this.input.shopRowId;
            delete this.input.sourceCode;
            this.input.shopAddress = "";
            this.input.province = "";
            this.input.city = "";
            this.input.cityRegion = "";
        },
        submit() {
            if (this.delayClick.get()) return;
            this.delayClick.init();
            if (this.shopType == "美团到餐") {
                this.input.sourceCode = "DianPing";
                this.input.shopName = "美团到餐";
                this.input.shopRowId = "1912055";
            } else if (this.shopType == "商宴通") {
                this.input.sourceCode = "SYT";
                this.input.shopName = "(未选择餐厅)";
                this.input.shopRowId = "1927962";
            }
            let validator = mealAddDemand;
            if (!this.input.isExtra) {
                validator = { ...validator, ...mealAddDemandXianXia };
            }
            let validateResult = this.validate(this.input, validator);
            if (!validateResult) return;
            this.input.proposalId = this.event.proposalId;
            miceService.saveMealItem(this.input).then(res => {
                if (res && res.success) {
                    this.$router.back();
                }
            });
        }
    }
};
</script>
<style lang="scss">
@import "addMealDemand.scss";
</style>
<template>
    <div class="addMealDemand">
        <Header backType="white" :back="true" :backEvent="true" @backEvent="back" />
        <div class="addMealDemand-container">
            <div class="publicHead">
                <p>外出用餐</p>
                <Tips :tips="tips" />
            </div>
            <div>
                <div class="btnRow">
                    <div class="btnRowTitle">用餐类型<span>*</span></div>
                    <div class="btnRowGroup">
                        <Btn
                            v-for="(item, index) in itemNameList"
                            :key="index"
                            :btnClass="input.itemName == item.txt ? 'btnRdio':'btnRdio btnRdioDis'"
                            @callBack="handleRadio(item, 'itemName')"
                        >
                            <span slot="btnContent">{{item.txt}}</span>
                        </Btn>
                    </div>
                </div>
                <div class="btnRow">
                    <div class="btnRowTitle">用餐形式<span>*</span></div>
                    <div class="btnRowGroup">
                        <Btn
                            v-for="(item, index) in mealTypeList"
                            :key="index"
                            :btnClass="input.mealType == item.txt ? 'btnRdio':'btnRdio btnRdioDis'"
                            @callBack="handleRadio(item, 'mealType')"
                        >
                            <span slot="btnContent">{{item.txt}}</span>
                        </Btn>
                    </div>
                </div>
                <div class="normalInputRow" @click="chooseHos">
                    <MInput title="选择医院" must="true" pla="请选择医院" :disabled="true" v-model="hospitalGPS.hospitalName"></MInput>
                </div>
                <div class="normalInputRow" @click="openComponent('dtStart')">
                    <MInput title="开始时间" must="true" :disabled="true" pla="请选择开始时间" v-model="input.dtStart"></MInput>
                </div>
                <div class="normalInputRow" @click="openComponent('dtEnd')">
                    <MInput title="结束时间" must="true" :disabled="true" pla="请选择结束时间" v-model="input.dtEnd"></MInput>
                </div>
                <div class="normalInputRow">
                    <MInput title="订餐人姓名" must="true" pla="请输入订餐人姓名" v-model="input.bookingName"></MInput>
                </div>
                <div class="normalInputRow">
                    <MInput title="订餐人手机" type="number" must="true" pla="请输入订餐人手机号" v-model="input.bookingPhoneNum"></MInput>
                </div>
                <div class="normalInputRow">
                    <MInput title="备注" pla="请输入备注信息" v-model="input.descr"></MInput>
                </div>
            </div>
            <div>
                <div v-if="shopType == '平台到餐'">
                    <div class="choose-shop-title">选择餐厅<span>*</span></div>
                    <div class="shop-item-btn" v-if="input.shopName">
                        <img :src="shop.imgThumbnail || (orderDetail.itsItemData || {}).shopImg" @error="setErrorImg" />
                        <div class="shop-name">{{input.shopName}}</div>
                        <Btn btnClass="delate-shop" @callBack="delateShop">
                            <span slot="btnContent">
                                <Svgs color="#fff" name="iconshanchu"></Svgs>
                            </span>
                        </Btn>
                    </div>
                    <div v-else class="add-btn" @click="openMeal">
                        <Svgs color="#C0C4CD" name="iconhao"></Svgs>
                    </div>
                </div>
            </div>
        </div>
        <Btn btnClass="submit-shop" @callBack="submit">
            <span slot="btnContent">保存</span>
        </Btn>
        <HospitalPop @onOk="chooseHosRes" ref="hospitalPop" />
    </div>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { mapGetters, mapMutations } from "vuex";
import { baseUrl } from "@/service/baseUrl.js";
import initTime from "@/utils/initTime.js"
import {
    mealAddDemand,
    mealAddDemandXianXia
} from "@/config/mealAddDemand.js";
export default {
    data() {
        return {
            input: {
                itemName: "", // 用餐类型
                mealType: "", // 用餐形式
                qty1: "", // 用餐人数
                amount: "", // 预计金额
                dtStart: "", // 用餐开始时间
                dtEnd: "", // 用餐结束时间
                bookingName: "", // 订餐人姓名
                bookingPhoneNum: "", // 订餐人电话
                descr: "", // 备注
                isMeetingPlace: true, //会议场所
                isExtra: "true", //"true"为线上餐，"false"为线下餐
                shopName: "", //餐厅名字
                shopMobile: "", //餐厅电话
                shopAddress: "", //餐厅地址
                province: "", //餐厅省份
                city: "", //餐厅城市
                cityRegion: "", //餐厅城市区域
                hospitalId: ""
            },
            shopType: "",
            itemNameList: [{ txt: "午餐" }, { txt: "晚餐" }],
            delayClick: null, //连续点击300毫秒延迟
            start: '',
            end: '',
            hospitalGPS: {},
            tips: [{
                txt: "特别提示：外出用餐必须在用餐当天20点前添加需求，并自行联系餐厅预订" 
            }],
        };
    },
    computed: {
        ...mapGetters(["event", "orderDetail", "memsg", "shop", "params", "hospitalGPS1"]),
        validator: {
            get: function() {
                var tenant_code = this.event.tenant_code || 'eventcool';
                return mealAddDemand[tenant_code + "Base"]
            }
        },
        mealTypeList: {
            get: function() {
                let list = [
                    { txt: "自助餐" },
                    { txt: "围餐" },
                    { txt: "商务用餐" }
                ]
                return list
            }
        },
    },
    created() {
        this.setDateRangeNovartis();
    },
    mounted() {
        this.delayClick = new this.delay();
        this.input.bookingName = this.memsg.dspName
        this.input.bookingPhoneNum = this.memsg.phoneNumber
        this.input.dtStart = this.fomatDateTime(new Date().getTime())
        this.input.dtEnd = this.fomatDateTime(new Date().getTime())
        this.init();
    },
    methods: {
        ...mapMutations(["ADDRESS", "PARAMS", "SHOP", "HOSPITAL_GPS", "FRESH_SHOP"]),
        setErrorImg(e) {
            var img = e.srcElement;
            img.src = require("@/assets/img/logo.png");
            img.οnerrοr = null;
        },
        setDateRangeNovartis() {
            let results = initTime(this.event.dtStart, this.event.dtEnd, 0, 0, 0)
            this.start = this.fomatDateTime(results.minDate);
            this.end = this.fomatDateTime(results.maxDate);
        },
        handleRadio(item, type) {
            if (type == "mealType") {
                this.input.mealType = item.txt;
            } else if (type == "itemName") {
                this.input.itemName = item.txt;
            }
        },
        openComponent(dateType) {
            this.$iDatePicker.render({
                format: 'YYYY-MM-DD hh:mm',
                startDate: this.start,
                endDate: this.end,
                value: dateType == 'dtStart' ? this.input.dtStart : this.input.dtEnd,
                onConfirm: function(data) {
                    if(dateType == 'dtStart') {
                        this.input.dtStart = data
                    } else {
                        this.input.dtEnd = data
                    }
                }.bind(this)
            })
        },
        init() {
            this.shopType = this.$route.query.shopType || "平台到餐";
            let orderDetail = this.orderDetail;
            let itsItemData = orderDetail.itsItemData || {};
            if (orderDetail.itemId) {
                this.input = {
                    itemId: orderDetail.itemId,
                    itemName: orderDetail.itemName,
                    mealType: itsItemData.mealType,
                    isExtra: orderDetail.isExtra,
                    qty1: orderDetail.qty1,
                    amount: orderDetail.amount,
                    dtStart: this.fomatDateTime(orderDetail.dtStart),
                    dtEnd: this.fomatDateTime(orderDetail.dtEnd),
                    isMeetingPlace: true,
                    shopName: itsItemData.shopName,
                    bookingName: itsItemData.bookingName,
                    bookingPhoneNum: itsItemData.bookingPhoneNum,
                    descr: orderDetail.descr
                };
                this.hospitalGPS = {
                    longitude: JSON.parse(itsItemData.gps).longitude,
                    latitude: JSON.parse(itsItemData.gps).latitude,
                    hospitalName: itsItemData.hospitalName,
                    rowId: itsItemData.hospitalId
                }
                if (orderDetail.isExtra) {
                    this.input.shopRowId = itsItemData.shopId;
                    this.input.sourceCode = orderDetail.sourceCode;
                    let temp = (orderDetail.itsItemData || { payProfile: "" })
                        .payProfile || { PayModes: [] };
                    let payMode =
                        typeof temp.PayModes == "object"
                            ? temp.PayModes[0]
                            : temp.PayModes;
                    if (payMode == 7) {
                        this.shopType = "美团到餐";
                    } else if (payMode == 9) {
                        this.shopType = "商宴通";
                    }
                } else {
                    this.input.shopMobile = itsItemData.mobile;
                    this.input.shopAddress = itsItemData.address;
                    this.input.province = itsItemData.province;
                    this.input.city = itsItemData.city;
                    this.input.cityRegion = itsItemData.cityRegion;
                }
            }
            if(JSON.stringify(this.params) != "{}") {
                this.input = this.params
                this.input.shopName = this.shop.fullName;
                this.input.isExtra = this.shop.isExtra;
                this.input.shopRowId = this.shop.rowId;
                this.input.sourceCode = this.shop.sourceCode;
                this.hospitalGPS = this.hospitalGPS1
            }
        },
        fomatDateTime(value, type) {
            if (!value) return;
            if (value.replace) {
                value = value.replace(/T/g, " ");
                value = value.replace(/-/g, "/");
            }
            var date = new Date(value);
            var year = date.getFullYear();
            var month =
                date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1;
            var _date =
                date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            var hour =
                date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            var minute =
                date.getMinutes() < 10
                    ? "0" + date.getMinutes()
                    : date.getMinutes();
            if(type) {
                let dateTime =
                    year + "-" + month + "-" + _date;
                return dateTime;
            } else {
                let dateTime =
                    year + "-" + month + "-" + _date + " " + hour + ":" + minute;
                return dateTime;
            }
        },
        // 选择医院
        chooseHos() {
            this.$refs.hospitalPop.openPop()
        },
        chooseHosRes(item) {
            this.hospitalGPS = {
                longitude: item.el,
                latitude: item.nl,
                hospitalName: item.name,
                rowId: item.rowId
            }
        },
        // 选择餐厅
        openMeal() {
            // this.input.shopName = "妙手湘湖南湘菜馆(澳门路店)";
            // this.input.shopRowId = 987639;
            // this.input.sourceCode = "KouBei";
            // return

            // this.input.shopName = "小南国 世茂店";
            // this.input.shopRowId = 1135176;
            // this.input.sourceCode = 'XiaoMiShu'
            // return
            
            if(!this.hospitalGPS.hospitalName) {
                this.toast("请先选择医院");
                return
            }
            let address = {
                name: this.hospitalGPS.hospitalName,
                latitude: this.hospitalGPS.latitude,
                longitude: this.hospitalGPS.longitude,
                city: this.event.cityDictVal
            }
            this["PARAMS"](this.input)
            this["ADDRESS"](address)
            this["HOSPITAL_GPS"](this.hospitalGPS)
            this["FRESH_SHOP"](true)
            this.$router.push('/shopList?refresh=true')
        },
        back() {
            this.reset()
            this.$router.push('/orderList');
        },
        reset() {
            this["ADDRESS"]({})
            this["PARAMS"]({})
            this["SHOP"]({});
            this["HOSPITAL_GPS"]({})
        },
        delateShop() {
            this.input.shopName = "";
            this.input.isExtra = "true";
            delete this.input.shopRowId;
            delete this.input.sourceCode;
            this.input.shopAddress = "";
            this.input.province = "";
            this.input.city = "";
            this.input.cityRegion = "";
            this["SHOP"]({});
        },
        submit() {
            if (this.delayClick.get()) return;
            this.delayClick.init();
            if (this.shopType == "美团到餐") {
                this.input.sourceCode = "DianPing";
                this.input.shopName = "美团到餐";
                this.input.shopRowId = "1912055";
            } else if (this.shopType == "商宴通") {
                this.input.sourceCode = "SYT";
                this.input.shopName = "(未选择餐厅)";
                this.input.shopRowId = "1927962";
            }
            this.input.hospitalId = this.hospitalGPS.rowId
            this.input.hospitalName = this.hospitalGPS.hospitalName
            this.input.gps = {
                longitude: this.hospitalGPS.longitude,
                latitude: this.hospitalGPS.latitude,
            }
            let validator = this.validator;
            if (!this.input.isExtra) {
                validator = { ...validator, ...mealAddDemandXianXia };
            }
            let validateResult = this.validate(this.input, validator);
            if (!validateResult) return;
            this.input.proposalId = this.event.proposalId;
            miceService.saveMealItem(this.input).then(res => {
                if (res && res.success) {
                    this.back()
                }
            });
        }
    }
};
</script>
<style lang="scss">
@import "addMealDemand.scss";
.addMealDemand {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    .addMealDemand-container {
        position: fixed;
        overflow: scroll;
        top: 0.4rem;
        bottom: 0;
        width: 100%;
        padding-bottom: 1rem;
    }
    .submit-shop {
        position: fixed;
        bottom: 0;
        width: calc(100% - 0.5rem);
    }
}   
</style>
<template>
    <div class="addMealDemand">
        <Header backType="white" :back="true" :backEvent="true" @backEvent="back" />
        <div class="addMealDemand-container">
            <div class="publicHead">
                <p>外出用餐</p>
                <Tips :tips="tips" />
            </div>
            <div>
                <div class="btnRow">
                    <div class="btnRowTitle">用餐类型<span>*</span></div>
                    <div class="btnRowGroup">
                        <Btn
                            v-for="(item, index) in itemNameList"
                            :key="index"
                            :btnClass="input.itemName == item.txt ? 'btnRdio':'btnRdio btnRdioDis'"
                            @callBack="handleRadio(item, 'itemName')"
                        >
                            <span slot="btnContent">{{item.txt}}</span>
                        </Btn>
                    </div>
                </div>
                <div class="btnRow">
                    <div class="btnRowTitle">用餐形式<span>*</span></div>
                    <div class="btnRowGroup">
                        <Btn
                            v-for="(item, index) in mealTypeList"
                            :key="index"
                            :btnClass="input.mealType == item.txt ? 'btnRdio':'btnRdio btnRdioDis'"
                            @callBack="handleRadio(item, 'mealType')"
                        >
                            <span slot="btnContent">{{item.txt}}</span>
                        </Btn>
                    </div>
                </div>
                 <div class="normalInputRow">
                    <MInput title="用餐人数" must="true" type='number' pla="请输入用餐人数" v-model="input.qty1"></MInput>
                </div>
                <div class="normalInputRow">
                    <MInput title="预计金额" must="true" pla="请输入预计金额" v-model="input.amount"></MInput>
                </div>
                <div class="normalInputRow" @click="openComponent('dtStart')">
                    <MInput title="开始时间" must="true" :disabled="true" pla="请选择开始时间" v-model="input.dtStart"></MInput>
                </div>
                <div class="normalInputRow" @click="openComponent('dtEnd')">
                    <MInput title="结束时间" must="true" :disabled="true" pla="请选择结束时间" v-model="input.dtEnd"></MInput>
                </div>
                <div class="normalInputRow">
                    <MInput title="订餐人姓名" must="true" pla="请输入订餐人姓名" v-model="input.bookingName"></MInput>
                </div>
                <div class="normalInputRow">
                    <MInput title="订餐人手机" type="number" must="true" pla="请输入订餐人手机号" v-model="input.bookingPhoneNum"></MInput>
                </div>
                <div class="normalInputRow">
                    <MInput title="备注" pla="请输入备注信息" v-model="input.descr"></MInput>
                </div>
            </div>
            <div>
                <div v-if="shopType == '平台到餐'">
                    <div class="choose-shop-title">选择餐厅<span>*</span></div>
                    <div class="shop-item-btn" v-if="input.shopName">
                        <img :src="shop.imgThumbnail || input.shopImg" @error="setErrorImg" />
                        <div class="shop-name">{{input.shopName}}</div>
                        <Btn btnClass="delate-shop" @callBack="delateShop">
                            <span slot="btnContent">
                                <Svgs color="#fff" name="iconshanchu"></Svgs>
                            </span>
                        </Btn>
                    </div>
                    <div v-else class="add-btn" @click="openMeal">
                        <Svgs color="#C0C4CD" name="iconhao"></Svgs>
                    </div>
                </div>
            </div>
        </div>
        <Btn btnClass="submit-shop" @callBack="submit">
            <span slot="btnContent">保存</span>
        </Btn>
    </div>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { mapGetters, mapMutations } from "vuex";
import { baseUrl } from "@/service/baseUrl.js";
import initTime from "@/utils/initTime.js"
import {
    mealAddDemand,
    mealAddDemandXianXia
} from "@/config/mealAddDemand.js";
import { setCookie,getCookie,getAllCookies } from 'tiny-cookie'
import {
    SmartStorage
} from 'smart-core-util'
export default {
    data() {
        return {
            input: {
                itemName: "", // 用餐类型
                mealType: "", // 用餐形式
                qty1: "", // 用餐人数
                amount: "", // 预计金额
                dtStart: "", // 用餐开始时间
                dtEnd: "", // 用餐结束时间
                bookingName: "", // 订餐人姓名
                bookingPhoneNum: "", // 订餐人电话
                descr: "", // 备注
                isMeetingPlace: true, //会议场所
                isExtra: "true", //"true"为线上餐，"false"为线下餐
                shopName: "", //餐厅名字
                shopMobile: "", //餐厅电话
                shopAddress: "", //餐厅地址
                province: "", //餐厅省份
                city: "", //餐厅城市
                cityRegion: "", //餐厅城市区域
                hospitalId: ""
            },
            shopType: "",
            itemNameList: [{ txt: "午餐" }, { txt: "晚餐" }],
            delayClick: null, //连续点击300毫秒延迟
            start: '',
            end: '',
            tips: [{
                txt: "需求信息（*为必填项）" 
            }],
            ItemId: '',
        };
    },
    computed: {
        ...mapGetters(["event", "orderDetail", "memsg", "shop", "params", "currentPosition", "cityAddress", 'address']),
        validator: {
            get: function() {
                var tenant_code = this.event.tenant_code || 'eventcool';
                return mealAddDemand[tenant_code + "Base"]
            }
        },
        mealTypeList: {
            get: function() {
                let list = [
                    { txt: "商务套餐" },
                    { txt: "桌餐" }
                ]
                return list
            }
        },
    },
    created() {
        this.setDateRange();
    },
    mounted() {
        this.delayClick = new this.delay();
        if(!(this.orderDetail || {}).itemId) {
            this.input.bookingName = this.memsg.dspName
            this.input.bookingPhoneNum = this.memsg.phoneNumber
        }
        this.init();
    },
    methods: {
        ...mapMutations(["ADDRESS", "PARAMS", "SHOP", "HOSPITAL_GPS", "FRESH_SHOP"]),
        setErrorImg(e) {
            var img = e.srcElement;
            img.src = require("@/assets/img/logo.png");
            img.οnerrοr = null;
        },
        setDateRange() {
            let results = initTime(this.event.dtStart, this.event.dtEnd, 0, 0, 0)
            this.start = this.fomatDateTime(results.minDate);
            this.end = this.fomatDateTime(results.maxDate);
        },
        handleRadio(item, type) {
            if (type == "mealType") {
                this.input.mealType = item.txt;
            } else if (type == "itemName") {
                this.input.itemName = item.txt;
            }
        },
        openComponent(dateType) {
            this.$iDatePicker.render({
                format: 'YYYY-MM-DD hh:mm',
                startDate: this.start,
                endDate: this.end,
                value: dateType == 'dtStart' ? this.input.dtStart : this.input.dtEnd,
                onConfirm: function(data) {
                    if(dateType == 'dtStart') {
                        this.input.dtStart = data
                    } else {
                        this.input.dtEnd = data
                    }
                }.bind(this)
            })
        },
        init() {
            this.shopType = this.$route.query.shopType || "平台到餐";
            let orderDetail = this.orderDetail;
            let itsItemData = orderDetail.itsItemData || {};
            if (orderDetail.itemId) {
                this.input = {
                    itemId: orderDetail.itemId,
                    itemName: orderDetail.itemName,
                    mealType: itsItemData.mealType,
                    isExtra: orderDetail.isExtra,
                    qty1: orderDetail.qty1,
                    amount: orderDetail.amount,
                    dtStart: this.fomatDateTime(orderDetail.dtStart),
                    dtEnd: this.fomatDateTime(orderDetail.dtEnd),
                    isMeetingPlace: true,
                    shopName: itsItemData.shopName,
                    bookingName: itsItemData.bookingName,
                    bookingPhoneNum: itsItemData.bookingPhoneNum,
                    descr: orderDetail.descr,
                    shopImg: itsItemData.shopImg
                };
                if (orderDetail.isExtra) {
                    this.input.shopRowId = itsItemData.shopId;
                    this.input.sourceCode = orderDetail.sourceCode;
                    let temp = (orderDetail.itsItemData || { payProfile: "" })
                        .payProfile || { PayModes: [] };
                    let payMode =
                        typeof temp.PayModes == "object"
                            ? temp.PayModes[0]
                            : temp.PayModes;
                    if (payMode == 7) {
                        this.shopType = "美团到餐";
                    } else if (payMode == 9) {
                        this.shopType = "商宴通";
                    }
                } else {
                    this.input.shopMobile = itsItemData.mobile;
                    this.input.shopAddress = itsItemData.address;
                    this.input.province = itsItemData.province;
                    this.input.city = itsItemData.city;
                    this.input.cityRegion = itsItemData.cityRegion;
                }
            }
            if(JSON.stringify(this.params) != "{}") {
                this.input = this.params
                this.input.shopName = this.shop.fullName;
                this.input.isExtra = this.shop.isExtra;
                this.input.shopRowId = this.shop.rowId;
                this.input.sourceCode = this.shop.sourceCode;
            }
        },
        fomatDateTime(value, type) {
            if (!value) return;
            if (value.replace) {
                value = value.replace(/T/g, " ");
                value = value.replace(/-/g, "/");
            }
            var date = new Date(value);
            var year = date.getFullYear();
            var month =
                date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1;
            var _date =
                date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            var hour =
                date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            var minute =
                date.getMinutes() < 10
                    ? "0" + date.getMinutes()
                    : date.getMinutes();
            if(type) {
                let dateTime =
                    year + "-" + month + "-" + _date;
                return dateTime;
            } else {
                let dateTime =
                    year + "-" + month + "-" + _date + " " + hour + ":" + minute;
                return dateTime;
            }
        },
        // 选择餐厅
        async openMeal() {
            if(this.currentPosition.city && this.currentPosition.city == this.event.cityDictVal) {
                this["ADDRESS"](this.currentPosition)
            } else {
                this["ADDRESS"](this.cityAddress)
            }
            let optionalType = await this.getWaimaiType()
			if(optionalType){
				this.openShopArr()
				return
			}
            // 定位失败或者定位不在同一个城市，取当前城市
            this["PARAMS"](this.input)
            this["FRESH_SHOP"](true)
            this.$router.push('/shopList?refresh=true')
        },
        async openShopArr(){
			let mealCombineData = [];
			const sessionIdData = await this.saveCacheData()
            let shopUrl = SmartStorage.get('Uris').Uris['webapp-imeal'] + '/shopList?refresh=true&type=selectShop&sessionId=' +  sessionIdData
            // let shopUrl = '/shopList?refresh=true&type=selectShop&sessionId=' +  sessionIdData
            mealCombineData.push({
				selected: 'https://spkg.smartmice.cn/resources/images/imeal/jh_ele_gd_xms_focus.png',
				normal: 'https://spkg.smartmice.cn/resources/images/imeal/jh_ele_gd_xms_blur.png',
				url: shopUrl
			})
			//获取美团地址`
            let meituanUrl = ''
			this.ItemId = this.generateUUID();
			let meituanParams ={
				ItemId: this.ItemId,
				longitude: this.address.longitude,
				latitude: this.address.latitude,
			}
			const meituanRes = await this.MeituanLogin(meituanParams)
			if (meituanRes) {
				meituanUrl = meituanRes;
			}else{
				return
			}
			mealCombineData.push({
				selected: 'https://spkg.smartmice.cn/resources/images/imeal/wm_mt_focus.png',
				normal: 'https://spkg.smartmice.cn/resources/images/imeal/wm_mt_blur.png',
				url: meituanUrl
			})
            let _this = this;
            const options = {
                method: "SMGeneral.openNewWebSiteForResult",
                postData:{
                    loadUrl: 'mealCombineData',
                    cookieData: getAllCookies(),
                    mealCombineData: mealCombineData,
                },
                callback: (viewData) => {
                    if(!viewData || viewData =='' || viewData == {} || viewData == "{}"){
                        setTimeout(() =>{
                            _this.getShop()
                        },800)
                        return
                    }
                    let postData = JSON.parse(viewData)
                    _this.input.shopName = postData.fullName;
                    _this.input.isExtra = postData.isExtra;
                    _this.input.shopRowId = postData.rowId;
                    _this.input.sourceCode = postData.sourceCode;
                    _this.input.imgThumbnail = postData.imgThumbnail
                }
            };
            this.iJsBridge.call(options);
        },
        // 获取餐厅信息
		async getShop() {
			let params = {
				ItemId: this.ItemId
			}
			// const res = await this.QuerySelectShop(params)
			const res = await new Promise((resolve,reject) => {
                miceService.QueryShop(params).then(res => {
                    if(res && res.success){
                        resolve(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            }) 
			if (res && res.success) {
				this.input.shopName = res.content.shopName || '';
				this.input.shopAddress = res.content.shopAddress || '';
				this.input.shopMobile = res.content.shopMobile || '';
				this.input.restaurantId = res.content.shopId || '';
				this.input.sourceCode = res.content.sourceCode || '';
				this.input.shopRowId = res.content.shopRowId || '';
				this.input.itemId = res.content.itemId || '';
				this.input.ExtOrderId = res.content.orderId || '';
				this.input.extOrderData = res.content.extOrderData || '';
				this.input.shopImg = res.content.shopImg || '';
				this.input.IsGenerateItemId = true;
			}
		},
        MeituanLogin(meituanParams){
            return new Promise((resolve,reject) => {
                miceService.meituanLogin(meituanParams).then(res => {
                    if(res && res.success){
                        resolve(res.content)
                    }
                }).catch(err => {
                    reject(err)
                })
            }) 
        },
        saveCacheData(){
			// 数据持久化保存数据
			let eventDataParams = {
                Data: {
                    eventData: this.event || {},
                    token: getCookie('token'),
                    proposalId: this.event.proposalId,
                    tenant_code: SmartStorage.get('tenant_code') || this.event.tenant_code,
                    tenant: SmartStorage.get('tenant_code') || this.event.tenant_code,
                    noBack: true,
                    paramCode: SmartStorage.get("paramCode")
                }
            }
            return new Promise((resolve,reject) => {
                miceService.saveCacheData(eventDataParams).then(sessionRes => {
                    if(sessionRes && sessionRes.success){
                        resolve(sessionRes.content)
                    }
                }).catch(err => {
                    reject(err)
                })
            }) 
        },
        // 获取外出用餐类型
        getWaimaiType() {
            let params = {
                collection: "cfg-imeal-channel",
                useCaching: false,
                cachingKey: "",
                filter: {
                    tenantCode: this.event.tenant_code || "crossevent",
                    mealType: "外出用餐"
                },
                projection: {
                    optionalType: 1,
                }
            };
            return new Promise((resolve, reject) => {   
                miceService.getOptItems(params).then(res => {
                    if (res && res.success && res.content) {
                        resolve((res.content[0] || {optionalType:[]}).optionalType.includes('美团'))
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
		generateUUID() {
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
				var r = Math.random() * 16 | 0,
					v = c == 'x' ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			});
		},
        back() {
            this.reset()
            this.$router.push('/orderList');
        },
        reset() {
            this["ADDRESS"]({})
            this["PARAMS"]({})
            this["SHOP"]({});
            this["HOSPITAL_GPS"]({})
        },
        delateShop() {
            this.input.shopName = "";
            this.input.isExtra = "true";
            delete this.input.shopRowId;
            delete this.input.sourceCode;
            this.input.shopAddress = "";
            this.input.province = "";
            this.input.city = "";
            this.input.cityRegion = "";
            this["SHOP"]({});
        },
        submit() {
            if (this.delayClick.get()) return;
            this.delayClick.init();
            if (this.shopType == "美团到餐") {
                this.input.sourceCode = "DianPing";
                this.input.shopName = "美团到餐";
                this.input.shopRowId = "1912055";
            } else if (this.shopType == "商宴通") {
                this.input.sourceCode = "SYT";
                this.input.shopName = "(未选择餐厅)";
                this.input.shopRowId = "1927962";
            }
            let validator = this.validator;
            if (!this.input.isExtra) {
                validator = { ...validator, ...mealAddDemandXianXia };
            }
            let validateResult = this.validate(this.input, validator);
            if (!validateResult) return;
            this.input.proposalId = this.event.proposalId;
            this.input.ItemType = '5';
            miceService.saveMealItem(this.input).then(res => {
                if (res && res.success) {
                    this.back()
                }
            });
        }
    }
};
</script>
<style lang="scss">
@import "addMealDemand.scss";
.addMealDemand {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    .addMealDemand-container {
        position: fixed;
        overflow: scroll;
        top: 0.4rem;
        bottom: 0;
        width: 100%;
        padding-bottom: 1rem;
    }
    .submit-shop {
        position: fixed;
        bottom: 0;
        width: calc(100% - 0.5rem);
    }
}   
</style>
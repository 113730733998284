import { mapGetters, mapMutations } from "vuex";
import {
    mealAddDemand,
    mealAddDemandXianXia
} from "@/config/mealAddDemand.js";
import { addMealDemandTips } from "@/config/Tips/addMealDemand.js"
export default {
    data() {
        return {
            input: {
                itemName: "", // 用餐类型
                mealType: "", // 用餐形式
                qty1: "", // 用餐人数
                amount: "", // 预计金额
                dtStart: "", // 用餐开始时间
                dtEnd: "", // 用餐结束时间
                bookingName: "", // 订餐人姓名
                bookingPhoneNum: "", // 订餐人电话
                descr: "", // 备注
                isMeetingPlace: true, //会议场所
                isExtra: "true", //"true"为线上餐，"false"为线下餐
                shopName: "", //餐厅名字
                shopMobile: "", //餐厅电话
                shopAddress: "", //餐厅地址
                province: "", //餐厅省份
                city: "", //餐厅城市
                cityRegion: "", //餐厅城市区域
                hospitalId: ""
            },
            shopType: "",
            delayClick: null, //连续点击300毫秒延迟
            start: '',
            end: '',
        }
    },
    computed: {
        ...mapGetters(["event", "orderDetail", "memsg", "shop", "params", "currentPosition", "cityAddress"]),
        validator: {
            get: function() {
                var tenant_code = this.event.tenant_code || 'eventcool';
                return mealAddDemand[tenant_code + "Base"]
            }
        },
        tips: {
            get: function() {
                var tenant_code = this.event.tenant_code || 'eventcool';
                return addMealDemandTips[tenant_code]
            }
        }
    },
    methods: {
        ...mapMutations(["ADDRESS", "PARAMS", "SHOP", "HOSPITAL_GPS", "FRESH_SHOP"]),
        init() {
            this.shopType = this.$route.query.shopType || "平台到餐";
            let orderDetail = this.orderDetail;
            let itsItemData = orderDetail.itsItemData || {};
            if (orderDetail.itemId) {
                this.input = {
                    itemId: orderDetail.itemId,
                    itemName: orderDetail.itemName,
                    mealType: itsItemData.mealType,
                    isExtra: orderDetail.isExtra,
                    qty1: orderDetail.qty1,
                    amount: orderDetail.amount,
                    dtStart: this.fomatDateTime(orderDetail.dtStart),
                    dtEnd: this.fomatDateTime(orderDetail.dtEnd),
                    isMeetingPlace: true,
                    shopName: itsItemData.shopName,
                    bookingName: itsItemData.bookingName,
                    bookingPhoneNum: itsItemData.bookingPhoneNum,
                    descr: orderDetail.descr
                };
                if (orderDetail.isExtra) {
                    this.input.shopRowId = itsItemData.shopId;
                    this.input.sourceCode = orderDetail.sourceCode;
                    let temp = (orderDetail.itsItemData || { payProfile: "" })
                        .payProfile || { PayModes: [] };
                    let payMode =
                        typeof temp.PayModes == "object"
                            ? temp.PayModes[0]
                            : temp.PayModes;
                    if (payMode == 7) {
                        this.shopType = "美团到餐";
                    } else if (payMode == 9) {
                        this.shopType = "商宴通";
                    }
                } else {
                    this.input.shopMobile = itsItemData.mobile;
                    this.input.shopAddress = itsItemData.address;
                    this.input.province = itsItemData.province;
                    this.input.city = itsItemData.city;
                    this.input.cityRegion = itsItemData.cityRegion;
                }
            }
            if(JSON.stringify(this.params) != "{}") {
                this.input = this.params
                this.input.shopName = this.shop.fullName;
                this.input.isExtra = this.shop.isExtra;
                if(this.shop.isExtra) {
                    this.input.shopRowId = this.shop.rowId;
                    this.input.sourceCode = this.shop.sourceCode;
                } else {
                    this.input.shopAddress = this.shop.shopAddress; 
                    this.input.province = this.shop.province;
                    this.input.city = this.shop.city;
                    this.input.cityRegion = this.shop.cityRegion;
                }
            }
        },
        setErrorImg(e) {
            var img = e.srcElement;
            img.src = require("@/assets/img/logo.png");
            img.οnerrοr = null;
        },
        handleRadio(item, type) {
            if (type == "mealType") {
                this.input.mealType = item.txt;
            } else if (type == "itemName") {
                this.input.itemName = item.txt;
            }
        },
        openComponent(dateType) {
            this.$iDatePicker.render({
                format: 'YYYY-MM-DD hh:mm',
                startDate: this.start,
                endDate: this.end,
                value: dateType == 'dtStart' ? this.input.dtStart : this.input.dtEnd,
                onConfirm: function(data) {
                    if(dateType == 'dtStart') {
                        this.input.dtStart = data
                    } else {
                        this.input.dtEnd = data
                    }
                }.bind(this)
            })
        },
        delateShop() {
            this.input.shopName = "";
            this.input.isExtra = "true";
            delete this.input.shopRowId;
            delete this.input.sourceCode;
            this.input.shopAddress = "";
            this.input.province = "";
            this.input.city = "";
            this.input.cityRegion = "";
            this["SHOP"]({});
        },
        back() {
            this.reset()
            this.$router.push('/orderList');
        },
        reset() {
            this["ADDRESS"]({})
            this["PARAMS"]({})
            this["SHOP"]({});
            this["HOSPITAL_GPS"]({})
        },
        formateTime(value) {
            if(value) {
                value = value.replace(/T/g, " ");
                value = value.replace(/-/g, "/");
            }
            return value
        },
        fomatDateTime(value, type) {
            if (!value) return;
            if (value.replace) {
                value = value.replace(/T/g, " ");
                value = value.replace(/-/g, "/");
            }
            var date = new Date(value);
            var year = date.getFullYear();
            var month =
                date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1;
            var _date =
                date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            var hour =
                date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            var minute =
                date.getMinutes() < 10
                    ? "0" + date.getMinutes()
                    : date.getMinutes();
            if(type) {
                let dateTime =
                    year + "-" + month + "-" + _date;
                return dateTime;
            } else {
                let dateTime =
                    year + "-" + month + "-" + _date + " " + hour + ":" + minute;
                return dateTime;
            }
        },
    },
}
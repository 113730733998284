<template>
    <div class="addMealDemand">
        <Header backType="white" :back="true" :backEvent="true" @backEvent="back" />
        <div class="addMealDemand-container">
            <div class="publicHead">
                <p>外出用餐</p>
                <Tips :tips="tips" />
            </div>
            <div>
                <div class="btnRow" v-if="event.tenant_name != '会引擎-huarun'">
                    <div class="btnRowTitle">用餐类型<span>*</span></div>
                    <div class="btnRowGroup">
                        <Btn
                            v-for="(item, index) in itemNameList"
                            :key="index"
                            :btnClass="input.itemName == item.txt ? 'btnRdio':'btnRdio btnRdioDis'"
                            @callBack="handleRadio(item, 'itemName')"
                        >
                            <span slot="btnContent">{{item.txt}}</span>
                        </Btn>
                    </div>
                </div>
                <div class="btnRow" v-if="event.tenant_name != '会引擎-huarun'">
                    <div class="btnRowTitle">用餐形式<span>*</span></div>
                    <div class="btnRowGroup">
                        <Btn
                            v-for="(item, index) in mealTypeList"
                            :key="index"
                            :btnClass="input.mealType == item.txt ? 'btnRdio':'btnRdio btnRdioDis'"
                            @callBack="handleRadio(item, 'mealType')"
                        >
                            <span slot="btnContent">{{item.txt}}</span>
                        </Btn>
                    </div>
                </div>
                 <div class="normalInputRow">
                    <MInput title="用餐人数" must="true" type='number' pla="请输入用餐人数" v-model="input.qty1"></MInput>
                </div>
                <div class="normalInputRow">
                    <MInput title="预计金额" must="true" pla="请输入预计金额" v-model="input.amount"></MInput>
                </div>
                <div class="normalInputRow" @click="openComponent('dtStart')">
                    <MInput title="开始时间" must="true" :disabled="true" pla="请选择开始时间" v-model="input.dtStart"></MInput>
                </div>
                <div class="normalInputRow" @click="openComponent('dtEnd')">
                    <MInput title="结束时间" must="true" :disabled="true" pla="请选择结束时间" v-model="input.dtEnd"></MInput>
                </div>
                <div class="normalInputRow">
                    <MInput title="订餐人姓名" must="true" pla="请输入订餐人姓名" v-model="input.bookingName"></MInput>
                </div>
                <div class="normalInputRow">
                    <MInput title="订餐人手机" type="number" must="true" pla="请输入订餐人手机号" v-model="input.bookingPhoneNum"></MInput>
                </div>
                <div class="normalInputRow">
                    <MInput title="备注" pla="请输入备注信息" v-model="input.descr"></MInput>
                </div>
            </div>
            <div>
                <div v-if="shopType == '平台到餐'">
                    <div class="choose-shop-title">选择餐厅<span>*</span></div>
                    <div class="shop-item-btn" v-if="input.shopName">
                        <img :src="shop.imgThumbnail || input.shopImg" @error="setErrorImg" />
                        <div class="shop-name">{{input.shopName}}</div>
                        <Btn btnClass="delate-shop" @callBack="delateShop">
                            <span slot="btnContent">
                                <Svgs color="#fff" name="iconshanchu"></Svgs>
                            </span>
                        </Btn>
                    </div>
                    <div v-else class="add-btn" @click="openMeal">
                        <Svgs color="#C0C4CD" name="iconhao"></Svgs>
                    </div>
                </div>
            </div>
        </div>
        <Btn btnClass="submit-shop" @callBack="submit">
            <span slot="btnContent">保存</span>
        </Btn>
    </div>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { mapGetters, mapMutations } from "vuex";
import { baseUrl } from "@/service/baseUrl.js";
import initTime from "@/utils/initTime.js"
import {
    mealAddDemand,
    mealAddDemandXianXia
} from "@/config/mealAddDemand.js";
import { getCookie } from 'tiny-cookie'
import { formatDate } from '@/core'
export default {
    data() {
        return {
            input: {
                itemName: "", // 用餐类型
                mealType: "", // 用餐形式
                qty1: "", // 用餐人数
                amount: "", // 预计金额
                dtStart: "", // 用餐开始时间
                dtEnd: "", // 用餐结束时间
                bookingName: "", // 订餐人姓名
                bookingPhoneNum: "", // 订餐人电话
                descr: "", // 备注
                isMeetingPlace: true, //会议场所
                isExtra: "true", //"true"为线上餐，"false"为线下餐
                shopName: "", //餐厅名字
                shopMobile: "", //餐厅电话
                shopAddress: "", //餐厅地址
                province: "", //餐厅省份
                city: "", //餐厅城市
                cityRegion: "", //餐厅城市区域
                hospitalId: ""
            },
            shopType: "",
            itemNameList: [{ txt: "午餐" }, { txt: "晚餐" }],
            delayClick: null, //连续点击300毫秒延迟
            start: '',
            end: '',
            tips: [{
                txt: "需求信息（*为必填项）" 
            }],
        };
    },
    computed: {
        ...mapGetters(["event", "orderDetail", "memsg", "shop", "params", "currentPosition", "cityAddress"]),
        validator: {
            get: function() {
                var tenant_code = this.event.tenant_code || 'eventcool';
                return mealAddDemand[tenant_code + "Base"]
            }
        },
        mealTypeList: {
            get: function() {
                let list = [
                    { txt: "商务套餐" },
                    { txt: "桌餐" }
                ]
                return list
            }
        },
    },
    created() {
        this.setDateRange();
        if(['院内会'].includes(this.event.typeDictTxt) && !['会引擎-huarun','会引擎-石药','会引擎-恩必普'].includes(this.event.tenant_name)){
            this.tips.push({
                txt: '预算标准：80元/人'
            })
        }else if(['城市会','专家沙龙'].includes(this.event.typeDictTxt) && !['会引擎-huarun','会引擎-石药','会引擎-恩必普'].includes(this.event.tenant_name)){
            this.tips.push({
                txt: '预算标准：300元/人'
            })
        }
        // 疾控提示
        if(['线上培训会'].includes(this.event.typeDictTxt) && this.event.tenant_name == '会引擎-疾控'){
            this.tips.push({
                txt: '每场会议餐标预算400元'
            })
        }
        if(['主题培训会'].includes(this.event.typeDictTxt) && this.event.tenant_name == '会引擎-疾控'){
            this.tips.push({
                txt: '每场会议餐标预算5000元'
            })
        }
        if(this.event.tenant_name == '会引擎-huarun'){
            this.input.itemName = "晚餐"
            this.input.mealType = "桌餐"
            this.tips.push({
                txt: '酒水金额不得超过消费总金额的35%'
            },{
                txt: '酒水不应包含茅台、五粮液等高端品牌'
            },)

        }
    },
    mounted() {
        this.delayClick = new this.delay();
        if(!(this.orderDetail || {}).itemId) {
            this.input.bookingName = this.memsg.dspName
            this.input.bookingPhoneNum = this.memsg.phoneNumber
        }
        this.init();
    },
    methods: {
        ...mapMutations(["ADDRESS", "PARAMS", "SHOP", "HOSPITAL_GPS", "FRESH_SHOP"]),
        setErrorImg(e) {
            var img = e.srcElement;
            img.src = require("@/assets/img/logo.png");
            img.οnerrοr = null;
        },
        setDateRange() {
            let results = initTime(this.event.dtStart, this.event.dtEnd, 0, 0, 0)
            if(this.event.tenant_name == '会引擎-石药' && ['自办会','赞助会','健康项目'].includes(this.event.typeDictTxt) && getCookie('tenant_id') != 'ee6ea2ae-f2c7-11ec-a826-be1305a58be3'){
               results = initTime(this.event.dtStart, this.event.dtEnd, 1, 1, 0)
            }
            if(this.event.tenant_name == '会引擎-恩必普' && ['县域会','城市会','省级会议','中央市场部活动','国家级学术会议','全国性会议'].includes(this.event.typeDictTxt)){
               results = initTime(this.event.dtStart, this.event.dtEnd, 1, 1, 0)
            }
            // 广阔市场单独判断
            if(getCookie('tenant_id') == 'ee6ea2ae-f2c7-11ec-a826-be1305a58be3' && ['学术会','病例研讨会','健康活动','患者教育','第三方赞助','专家点评会','专家AB会','健康大讲堂','线上直播','学术沙龙','官方会议类赞助','上市发布会','幻灯点评会','院内会','项目合作','内训会','专家咨询服务','大型学术会','捐赠'].includes(this.event.typeDictTxt)){
                results = initTime(this.event.dtStart, this.event.dtEnd, 1, 1, 0)
            }
            // 科伦
            if(getCookie('tenant_id') == '51499834-da35-11ec-bdf1-0a1c87dc4eec'){
                results = initTime(this.event.dtStart, this.event.dtEnd, 1, 1, 0)
            }
            // 石药药品
            if(getCookie('tenant_id') == '207d3942-395e-11ed-90ea-863f87f6346f' && ["中央学术活动","区域市场学术活动"].includes(this.event.typeDictTxt)){
                results = initTime(this.event.dtStart, this.event.dtEnd, 1, 1, 0)
            }
            this.start = this.fomatDateTime(results.minDate);
            this.end = this.fomatDateTime(results.maxDate);
        },
        handleRadio(item, type) {
            if (type == "mealType") {
                this.input.mealType = item.txt;
            } else if (type == "itemName") {
                this.input.itemName = item.txt;
            }
        },
        openComponent(dateType) {
            let start = this.start;
            let end = this.end;
            this.$iDatePicker.render({
                format: 'YYYY-MM-DD hh:mm',
                startDate: start,
                endDate: end,
                value: dateType == 'dtStart' ? this.input.dtStart : this.input.dtEnd,
                onConfirm: function(data) {
                    if(dateType == 'dtStart') {
                        this.input.dtStart = data
                    } else {
                        this.input.dtEnd = data
                    }
                }.bind(this)
            })
        },
        init() {
            this.shopType = this.$route.query.shopType || "平台到餐";
            let orderDetail = this.orderDetail;
            let itsItemData = orderDetail.itsItemData || {};
            if (orderDetail.itemId) {
                this.input = {
                    itemId: orderDetail.itemId,
                    itemName: orderDetail.itemName,
                    mealType: itsItemData.mealType,
                    isExtra: orderDetail.isExtra,
                    qty1: orderDetail.qty1,
                    amount: orderDetail.amount,
                    dtStart: this.fomatDateTime(orderDetail.dtStart),
                    dtEnd: this.fomatDateTime(orderDetail.dtEnd),
                    isMeetingPlace: true,
                    shopName: itsItemData.shopName,
                    bookingName: itsItemData.bookingName,
                    bookingPhoneNum: itsItemData.bookingPhoneNum,
                    descr: orderDetail.descr,
                    shopImg: itsItemData.shopImg
                };
                if (orderDetail.isExtra) {
                    this.input.shopRowId = itsItemData.shopId;
                    this.input.sourceCode = orderDetail.sourceCode;
                    let temp = (orderDetail.itsItemData || { payProfile: "" })
                        .payProfile || { PayModes: [] };
                    let payMode =
                        typeof temp.PayModes == "object"
                            ? temp.PayModes[0]
                            : temp.PayModes;
                    if (payMode == 7) {
                        this.shopType = "美团到餐";
                    } else if (payMode == 9) {
                        this.shopType = "商宴通";
                    }
                } else {
                    this.input.shopMobile = itsItemData.mobile;
                    this.input.shopAddress = itsItemData.address;
                    this.input.province = itsItemData.province;
                    this.input.city = itsItemData.city;
                    this.input.cityRegion = itsItemData.cityRegion;
                }
            }
            if(JSON.stringify(this.params) != "{}") {
                this.input = this.params
                this.input.shopName = this.shop.fullName;
                this.input.isExtra = this.shop.isExtra;
                this.input.shopRowId = this.shop.rowId;
                this.input.sourceCode = this.shop.sourceCode;
                this.input.mobile = this.shop.tel || '';
            }
        },
        fomatDateTime(value, type) {
            if (!value) return;
            if (value.replace) {
                value = value.replace(/T/g, " ");
                value = value.replace(/-/g, "/");
            }
            var date = new Date(value);
            var year = date.getFullYear();
            var month =
                date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1;
            var _date =
                date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            var hour =
                date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            var minute =
                date.getMinutes() < 10
                    ? "0" + date.getMinutes()
                    : date.getMinutes();
            if(type) {
                let dateTime =
                    year + "-" + month + "-" + _date;
                return dateTime;
            } else {
                let dateTime =
                    year + "-" + month + "-" + _date + " " + hour + ":" + minute;
                return dateTime;
            }
        },
        // 选择餐厅
        openMeal() {
            if(this.currentPosition.city && this.currentPosition.city == this.event.cityDictVal) {
                this["ADDRESS"](this.currentPosition)
            } else {
                this["ADDRESS"](this.cityAddress)
            }
            
            // 定位失败或者定位不在同一个城市，取当前城市
            this["PARAMS"](this.input)
            this["FRESH_SHOP"](true)
            this.$router.push('/shopList?refresh=true')
        },
        back() {
            this.reset()
            this.$router.push('/orderList');
        },
        reset() {
            this["ADDRESS"]({})
            this["PARAMS"]({})
            this["SHOP"]({});
            this["HOSPITAL_GPS"]({})
        },
        delateShop() {
            this.input.shopName = "";
            this.input.isExtra = "true";
            delete this.input.shopRowId;
            delete this.input.sourceCode;
            this.input.shopAddress = "";
            this.input.province = "";
            this.input.city = "";
            this.input.cityRegion = "";
            this["SHOP"]({});
        },
        submit() {
            console.log('this.input',this.input);
            if (this.delayClick.get()) return;
            this.delayClick.init();
            if (this.shopType == "美团到餐") {
                this.input.sourceCode = "DianPing";
                this.input.shopName = "美团到餐";
                this.input.shopRowId = "1912055";
            } else if (this.shopType == "商宴通") {
                this.input.sourceCode = "SYT";
                this.input.shopName = "(未选择餐厅)";
                this.input.shopRowId = "1927962";
            }
            // this.input.dtEnd = "2022-02-09 19:18"
            let validator = this.validator;
            let validateResult = this.validate(this.input, validator);
            if (!validateResult) return;
            var currentDate = new Date();
            var currentYear  = currentDate.getFullYear()
            var currentMonth = currentDate.getMonth()
            var currentDay = currentDate.getDate()
            var currentHours = currentDate.getHours()
            var date = new Date(currentYear, currentMonth, currentDay).getTime()
            var dtStartTime = new Date(this.input.dtStart).getTime()
            var dtEndTime = new Date(this.input.dtEnd).getTime()
            if(dtStartTime>= date && dtEndTime<= date+86400000 && currentHours>=20 && ['会引擎-石药','会引擎-恩必普'].includes(this.event.tenant_name)){
                this.toast("当天用餐20:00以后不能添加");  
                return
            }
            this.input.proposalId = this.event.proposalId;
            this.input.ItemType = '5';
            miceService.saveMealItem(this.input).then(res => {
                if (res && res.success) {
                    this.back()
                }
            });
        }
    }
};
</script>
<style lang="scss">
@import "addMealDemand.scss";
.addMealDemand {
    width: 100%;
    .addMealDemand-container {
        position: fixed;
        overflow: scroll;
        top: 0.4rem;
        bottom: 0;
        width: 100%;
        padding-bottom: 1rem;
    }
    .submit-shop {
        position: fixed;
        bottom: 0;
        width: calc(100% - 0.5rem);
    }
}   
</style>
<template>
    <div class="addMealDemand">
        <Header
            backType="white"
            :back="true"
            :backEvent="true"
            @backEvent="back"
        />
        <div class="addMealDemand-container">
            <div class="publicHead1">
                <p>外出用餐</p>
            </div>
            <AttentionTips v-if="tips.length" :tips="tips" />
            <div>
                <div class="btnRow">
                    <div class="btnRowTitle">
                        用餐类型
                        <span>*</span>
                    </div>
                    <div class="btnRowGroup">
                        <Btn
                            v-for="(item, index) in itemNameList"
                            :key="index"
                            :btnClass="
                                input.itemName == item.txt
                                    ? 'btnRdio'
                                    : 'btnRdio btnRdioDis'
                            "
                            @callBack="handleRadio(item, 'itemName')"
                        >
                            <span slot="btnContent">{{ item.txt }}</span>
                        </Btn>
                    </div>
                </div>
                <div class="normalInputRow">
                    <MInput
                        title="用餐人数"
                        must="true"
                        pla="请输入用餐人数"
                        v-model="input.qty1"
                    ></MInput>
                </div>
                <div class="normalInputRow">
                    <MInput
                        title="预计金额"
                        must="true"
                        pla="请输入预计金额"
                        v-model="input.amount"
                    ></MInput>
                </div>
                <div
                    class="normalInputRow"
                    @click="openComponent('dtStart')"
                >
                    <MInput
                        title="开始时间"
                        must="true"
                        :disabled="true"
                        pla="请选择开始时间"
                        v-model="input.dtStart"
                    ></MInput>
                </div>
                <div
                    class="normalInputRow"
                    @click="openComponent('dtEnd')"
                >
                    <MInput
                        title="结束时间"
                        must="true"
                        :disabled="true"
                        pla="请选择结束时间"
                        v-model="input.dtEnd"
                    ></MInput>
                </div>
                <div class="normalInputRow">
                    <MInput
                        title="订餐人姓名"
                        must="true"
                        pla="请输入订餐人姓名"
                        v-model="input.bookingName"
                    ></MInput>
                </div>
                <div class="normalInputRow">
                    <MInput
                        title="订餐人手机"
                        type="number"
                        must="true"
                        pla="请输入订餐人手机号"
                        v-model="input.bookingPhoneNum"
                    ></MInput>
                </div>
                <div class="normalInputRow">
                    <MInput
                        title="备注"
                        pla="请输入备注信息"
                        v-model="input.descr"
                    ></MInput>
                </div>
            </div>
            <div>
                <div v-if="shopType == '平台到餐'">
                    <div class="choose-shop-title">
                        选择餐厅
                        <span>*</span>
                    </div>
                    <div class="shop-item-btn" v-if="input.shopName">
                        <img :src="shop.imgThumbnail" @error="setErrorImg" />
                        <div class="shop-name">{{ input.shopName }}</div>
                        <Btn btnClass="delate-shop" @callBack="delateShop">
                            <span slot="btnContent">
                                <Svgs color="#fff" name="iconshanchu"></Svgs>
                            </span>
                        </Btn>
                    </div>
                    <div v-else class="add-btn" @click="openMeal">
                        <Svgs color="#C0C4CD" name="iconhao"></Svgs>
                    </div>
                </div>
            </div>
        </div>
        <Btn btnClass="submit-shop" @callBack="submit">
            <span slot="btnContent">保存</span>
        </Btn>
        <MessageBox ref="confim" type="tips" @onOk="ok">
            <div slot="messageSlot">
                <Svgs
                    name="iconwarn"
                    color="#2D82F0"
                    classes="messageBoxSvg"
                ></Svgs>
                <div v-html="text"></div>
            </div>
        </MessageBox>
    </div>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { baseUrl } from "@/service/baseUrl.js";
import publicFun from "./publicFun.js";
import initTime from "@/utils/initTime.js";
export default {
    mixins: [publicFun],
    data() {
        return {
            itemNameList: [{ txt: "午餐" }, { txt: "晚餐" }],
            text: ""
        };
    },
    created() {
        this.setDateRange();
    },
    mounted() {
        this.delayClick = new this.delay();
        if (!(this.orderDetail || {}).itemId) {
            this.input.bookingName = this.memsg.dspName;
            this.input.bookingPhoneNum = this.memsg.phoneNumber;
        }
        this.init();
    },
    methods: {
        setDateRange() {
            let results;
            if (["跨院云会议"].includes(this.event.typeDictTxt)) {  // 只能会议当天
                results = initTime(
                    this.event.dtStart,
                    this.event.dtEnd,
                    0,
                    0,
                    0
                );
            } else {
                results = initTime(
                    this.event.dtStart,
                    this.event.dtEnd,
                    1,
                    1,
                    0
                );
            }
         
            this.start = this.fomatDateTime(results.minDate);
            this.end = this.fomatDateTime(results.maxDate);
        },
        timeIndate() {
            var event = this.event;
            var eventStartDate = new Date(
                this.formateTime(event.dtStart)
            ).getTime(); //会议时间
            var eventEndDate = new Date(
                this.formateTime(event.dtEnd)
            ).getTime();
            var minDate = eventStartDate; //开始时间
            var maxDate = eventEndDate + 86400000 - 60 * 1000; //会议时间

            let dtStart = new Date(
                this.input.dtStart.replace(/-/g, "/")
            ).getTime();
            let dtEnd = new Date(this.input.dtEnd.replace(/-/g, "/")).getTime();
            if (event.dtTypeTxt === "上午会") {
                return maxDate < dtEnd;
            } else if (event.dtTypeTxt === "下午会") {
                return minDate > dtStart;
            } else { 
                return false;
            }
        },
        // 选择餐厅 
        openMeal() {
            // this.input.shopName = "妙手湘湖南湘菜馆(澳门路店)";
            // this.input.shopRowId = 987639;
            // this.input.sourceCode = "KouBei";
            // return

            // this.input.shopName = "小南国 世茂店";
            // this.input.shopRowId = 1135176;
            // this.input.sourceCode = 'XiaoMiShu'
            // return
            if (
                this.currentPosition.city &&
                this.currentPosition.city == this.event.cityDictVal
            ) {
                this["ADDRESS"](this.currentPosition);
            } else {
                this["ADDRESS"](this.cityAddress);
            }
            // 定位失败或者定位不在同一个城市，取当前城市
            this["PARAMS"](this.input);
            this["FRESH_SHOP"](true);
            this.$router.push("/shopList?refresh=true");
        },
        submit() {
            if (this.delayClick.get()) return;
            this.delayClick.init();
            if (this.shopType == "美团到餐") {
                this.input.sourceCode = "DianPing";
                this.input.shopName = "美团到餐";
                this.input.shopRowId = "1912055";
            } else if (this.shopType == "商宴通") {
                this.input.sourceCode = "SYT";
                this.input.shopName = "(未选择餐厅)";
                this.input.shopRowId = "1927962";
            }
            let validator = this.validator;
            if (!this.input.isExtra) {
                /* global mealAddDemandXianXia */
                validator = { ...validator, ...mealAddDemandXianXia };
            }
            let validateResult = this.validate(this.input, validator);
            if (!validateResult) return;
            this.input.proposalId = this.event.proposalId;
            this.input.miceId = this.event.proposalId;
            if (!this.input.isExtra) {
                this.text = `<div style='font-size: 14px;text-align: left;'>您选择的是非YES餐厅库的餐厅，请注意<br />
                    1.餐厅地址不能在风景度假区或相关类似娱乐休闲区<br />
                    2.餐厅名称不能包含“度假、温泉、会所、洗浴、娱乐、豪华、休闲”等敏感字眼<br />
                    3.餐厅能够开具正规发票和机打水单(小票)<br />
                    4.餐厅不允许提供储值卡、代金券、礼盒、礼券等，不允许以任何形式返还现金<br />
                    5.餐厅不允许提供场所内的任何娱乐设</div>`;
                this.$refs.confim.openPop();
            } else if (this.params.isExtra && this.timeIndate()) {
                this.text =
                    "会议日期与用餐日期存在差异，请确认当前选择，并保证这样的安排是合理且符合公司规定的";
                this.$refs.confim.openPop();
            } else {
                this.ok();
            }
        },
        ok() {
            miceService.saveMealItem(this.input).then((res) => {
                if (res && res.success) {
                    this.back();
                }
            });
        },
    },
};
</script>
<style lang="scss">
@import "addMealDemand.scss";
.addMealDemand {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    .addMealDemand-container {
        position: fixed;
        overflow: scroll;
        top: 0.4rem;
        bottom: 0;
        width: 100%;
        padding-bottom: 1rem;
    }
    .submit-shop {
        position: fixed;
        bottom: 0;
        width: calc(100% - 0.5rem);
    }
}
</style>
function initTime(start, end, upday, endDay, nextDay, today) {
    /*
        start 会议开始时间
        end 会议结束时间
        upday 会议前XX天
        endDay 会议后几天
        nextDay 距今多少天
        today 结束时间和开始时间是否同一天
    */
    start = start.replace(/T/g, " ");
    start = start.replace(/-/g, "/");
    end = end.replace(/T/g, " ");
    end = end.replace(/-/g, "/");

    let eventStartDate = new Date(start).getTime(); // 会议时间当天
    let eventEndDate = new Date(end).getTime() + 1*86400000 -1; // 会议结束时间
    let currentDate = new Date().getTime(); // 当前时间
    let upDate = eventStartDate - upday * 86400000 // 会议前几天
    let endDate = eventEndDate + endDay * 86400000 // 会议后几天
    let nextDate = currentDate + nextDay * 86400000

    let minDate = nextDate > upDate ? nextDate : upDate; //开始时间
    let maxDate = endDate; 
    if(minDate>maxDate) {
        minDate = maxDate  = currentDate 
    }

    if(today){
        maxDate = new Date(new Date(start).setHours(23,59,59,999)).getTime()
        // nextDate > upDate ? maxDate = new Date(new Date().setHours(23,59,59,999)).getTime() : maxDate = minDate + 86400000 -1; 
    }

    return {minDate: minDate, maxDate: maxDate}
}

export default initTime